<template>
  <trev-product-skeleton-list></trev-product-skeleton-list>
</template>

<script>
import trevProductSkeletonList from '../../../../../components/products/productskeletons/trev-product-skeleton-list.vue'
export default {
  components: { trevProductSkeletonList },
  metaInfo: () => ({
    title: 'Product Skeleton List'
  })
}
</script>

<style>

</style>